import { queryOptions, useQuery } from '@tanstack/react-query';
import { queryClient } from 'store/queryClient';
import { API } from 'utilities/api/domain';
import { createBaseQueryKey } from 'utilities/methods/tanstack/createBaseQueryKey';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
function createQueryKey(domainId: number) {
    return createBaseQueryKey(['domain', domainId, 'information']);
}

function createQueryOptions(domainId: number) {
    return queryOptions({
        queryKey: createQueryKey(domainId),
        queryFn: () => API.domain.GET.information(domainId),
        enabled: Boolean(domainId),
        select: (response) => {
            if (response.status === 200) {
                return response.data.data;
            }
        },
    });
}

export function invalidateQueries(domainId: number) {
    return queryClient.invalidateQueries({ queryKey: createQueryKey(domainId) });
}

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
function _useQuery(domainId: number) {
    return useQuery(createQueryOptions(domainId));
}

/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/
export const getDomainInfo = Object.freeze({
    useQuery: _useQuery,
});
