import { useMutation } from '@tanstack/react-query';
import { pushNotification } from 'components/Toast/functions';
import { API } from 'utilities/api/domain';
import { NXQuery } from 'utilities/query';

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
function _useMutation(domainId: string) {
    function mutationFn() {
        return API.domain.POST.reset(domainId);
    }

    /***** HOOK RESULTS *****/
    return useMutation({
        mutationFn,
        onSuccess: (response) => {
            NXQuery.domain.domainID.dns.getRecord.invalidateQueries(domainId);
            pushNotification(response.data);
        }
    });
}
/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/

export const reset = Object.freeze({
    useMutation: _useMutation
});
