import { FormItem } from 'components/Form/FormItem';
import { FormItemInner } from 'components/Form/FormItemInner';
import { FormLabel } from 'components/Form/FormLabel';
import { _Input as Input } from 'components/Form/Input/_Input';
import type { NXInputNamespace } from 'components/Form/Input/types';
import mergeRefs from 'merge-refs';
import React from 'react';
import { useController } from 'react-hook-form';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const _InputHookFormDefault: _InputHookFormDefault.InputHookForm = ({
    label,
    disabled,
    placeholder,
    isPlaceholderItalic,
    name,
    inputRef,
    size,
    type,
    onChangeTransform,
    valueTransform,
    FormItemInnerVariant = FormItemInner.HookForm,
    intrinsicProps,
}: NXInputNamespace.HookForm.Props<TTransformValue>) => {
    /***** HOOKS *****/
    const { field } = useController({ name });
    const { onChange, onBlur, ref, value } = field;

    /***** FUNCTIONS *****/
    const handleOnChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        const value = e.target.value;
        onChange(onChangeTransform ? onChangeTransform(value) : value);
    };

    const handleOnBlur: React.FocusEventHandler<HTMLInputElement> = (e) => {
        onBlur();
        intrinsicProps?.onBlur?.(e);
    };

    /***** RENDER HELPERS *****/
    const _value = valueTransform ? valueTransform(value) : value;

    /***** RENDER *****/
    return (
        <FormItem disabled={disabled} name={name} size={size}>
            <FormLabel htmlFor={name || ''}>{label}</FormLabel>
            <FormItemInnerVariant name={name}>
                <Input
                    disabled={disabled}
                    name={name}
                    type={type ? type : 'text'}
                    placeholder={placeholder}
                    isPlaceholderItalic={isPlaceholderItalic}
                    value={_value}
                    inputRef={mergeRefs(ref, inputRef)}
                    intrinsicProps={{ ...intrinsicProps, onChange: handleOnChange, onBlur: handleOnBlur }}
                />
            </FormItemInnerVariant>
        </FormItem>
    );
};
