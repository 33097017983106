import classNames from 'classnames';
import type { NXInputNamespace } from 'components/Form/Input/types';
import { createAppliedStylingClasses } from 'components/Utils/methods';
import './_Input.scss';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type _Input = React.FC<NXInputNamespace.BaseProps>;

const inputAppliedStylingPropKeys = ['readOnly', 'disabled', 'isPlaceholderItalic'];

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const _Input: _Input = (props) => {
    const { value, name, placeholder, disabled, spellCheck = false, intrinsicProps = {}, size = 'full', readOnly, inputRef, type = 'text' } = props;
    const { className, ...restIntrinsicProps } = intrinsicProps;

    /***** RENDER HELPERS *****/
    const appliedClassNames = createAppliedStylingClasses({
        props,
        keyBoundary: inputAppliedStylingPropKeys,
        delimiter: '--',
        componentName: 'NXInput',
    });

    /***** RENDER *****/
    return (
        <input
            className={classNames(
                'NXInput',
                appliedClassNames,
                {
                    [`NXInput--size-${size}`]: size,
                },
                className
            )}
            ref={inputRef}
            value={value || ''}
            name={name}
            type={type}
            disabled={disabled || readOnly}
            placeholder={placeholder}
            spellCheck={spellCheck}
            {...restIntrinsicProps}
        />
    );
};
