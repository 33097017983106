import { useMutation } from '@tanstack/react-query';
import { pushNotification } from 'components/Toast/functions';
import type { DomainNamespace } from 'containers/domain/types';
import { useState } from 'react';
import { queryClient } from 'store/queryClient';
import { API } from 'utilities/api/domain';
import { NXQuery } from 'utilities/query';

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
function _useMutation(domainId: string) {
    const [recordOptimisticID, setRecordOptimisticID] = useState<string | null>(null);

    function mutationFn(recordId: string) {
        setRecordOptimisticID(recordId);
        return API.domain.DELETE.record(domainId, recordId);
    }

    /***** HOOK RESULTS *****/
    return useMutation({
        mutationFn,
        onSuccess: (response) => {
            queryClient.setQueryData(NXQuery.domain.domainID.dns.getRecord.createQueryKey(domainId), (oldData: any) => {
                const newData = {
                    ...oldData,
                    data: {
                        ...oldData.data,
                        data: {
                            ...oldData.data.data,
                            attributes: {
                                ...oldData.data.data.attributes,
                                dns_records: oldData.data.data.attributes.dns_records.filter(
                                    (record: DomainNamespace.DNS.DNSRecord) => record.id !== recordOptimisticID
                                ),
                            },
                        },
                    },
                };

                return newData;
            });
            NXQuery.domain.domainID.dns.getRecord.invalidateQueries(domainId);
            pushNotification(response.data);
        },
    });
}
/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/

export const deleteRecord = Object.freeze({
    useMutation: _useMutation,
});
