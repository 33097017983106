/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useEffect } from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import RequestLoader from 'components/Loaders/Request';

/*   ACTIONS
 *****************************************************/
import { useNavigate, useSearch } from '@tanstack/react-router';
import { useLoginContext } from 'containers/login/consts';
import { NXQuery } from 'utilities/query';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
function VipcAppTwoFactorPolling() {
    /***** HOOKS *****/
    const navigate = useNavigate();
    const { ref } = useSearch({ from: '/_login/login' });
    const { setView } = useLoginContext();

    /***** QUERIES *****/
    const { isSuccess: isCheckTokenSuccess } = NXQuery.auth.login.checkToken.useQuery(void 0, {
        refetchInterval: 10000
    });
    const { isSuccess: isAccountListSuccess, data: totalAccounts } = NXQuery.auth.accountList.useQuery(void 0, {
        enabled: isCheckTokenSuccess,
        select: ({ data }) => data?.length
    });

    /***** EFFECTS *****/
    useEffect(() => {
        if (isCheckTokenSuccess && isAccountListSuccess) {
            if (totalAccounts === 1) {
                NXQuery.auth.userData.fetch().then(() => {
                    navigate({ to: ref ?? '/dashboard' });
                });
            } else {
                setView('accountlist');
            }
        }
    }, [isCheckTokenSuccess, isAccountListSuccess]);

    /***** RENDER *****/
    return <RequestLoader />;
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default VipcAppTwoFactorPolling;
