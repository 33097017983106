import { auEligibility } from 'containers/domain/queries/auEligibility';
import { availability } from 'containers/domain/queries/availability';
import { configureDNSMode } from 'containers/domain/queries/domainID/configureDNSMode';
import { addRecord } from 'containers/domain/queries/domainID/dns/addRecord';
import { deleteRecord } from 'containers/domain/queries/domainID/dns/deleteRecord';
import { getRecord } from 'containers/domain/queries/domainID/dns/getRecord';
import { reset } from 'containers/domain/queries/domainID/dns/reset';
import { updateRecord } from 'containers/domain/queries/domainID/dns/updateRecord';
import { getDNSPresetPreview } from 'containers/domain/queries/domainID/getDNSPresetPreview';
import { getDomainInfo } from 'containers/domain/queries/domainID/getDomainInfo';
import { eppValidation } from 'containers/domain/queries/eppValidation';
import { domainsList } from 'containers/domain/queries/getDomainsList';
import { domainsSearch } from 'containers/domain/queries/searchDomainsList';
import { suggestion } from 'containers/domain/queries/suggestion';

export const _domainQuery = Object.freeze({
    domainsList,
    domainsSearch,
    domainID: {
        getDomainInfo,
        getDNSPresetPreview,
        configureDNSMode,
        auEligibility,
        dns: {
            getRecord,
            updateRecord,
            addRecord,
            deleteRecord,
            reset
        }
    },
    availability,
    eppValidation,
    suggestion
});
