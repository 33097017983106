import { useMutation } from '@tanstack/react-query';
import { pushNotification } from 'components/Toast/functions';
import { queryClient } from 'store/queryClient';
import { API } from 'utilities/api/domain';
import { NXQuery } from 'utilities/query';

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
function _useMutation(domainId: string) {
    function mutationFn(attributes: object) {
        return API.domain.POST.record(domainId, attributes);
    }

    /***** HOOK RESULTS *****/
    return useMutation({
        mutationFn,
        onSuccess: (response) => {
            queryClient.setQueryData(NXQuery.domain.domainID.dns.getRecord.createQueryKey(domainId), (oldData: any) => {
                const newData = {
                    ...oldData,
                    data: {
                        ...oldData.data,
                        data: { ...oldData.data.data, attributes: { ...oldData.data.data.attributes, dns_records: response.data.data.data } },
                    },
                };
                return newData;
            });
            NXQuery.domain.domainID.dns.getRecord.invalidateQueries(domainId);
            pushNotification(response.data);
        },
        onError: (error) => {
            pushNotification(error.response.data.errors[0]);
        },
    });
}
/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/

export const addRecord = Object.freeze({
    useMutation: _useMutation,
});
