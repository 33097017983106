import { useMutation } from '@tanstack/react-query';
import { pushNotification } from 'components/Toast/functions';
import { queryClient } from 'store/queryClient';
import { API } from 'utilities/api/domain';
import { NXQuery } from 'utilities/query';

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
function _useMutation(domainId: string) {
    function mutationFn({ recordId, attributes }) {
        return API.domain.PUT.record(domainId, recordId, attributes);
    }

    /***** HOOK RESULTS *****/
    return useMutation({
        mutationFn,
        onSuccess: (response) => {
            queryClient.setQueryData(NXQuery.domain.domainID.dns.getRecord.createQueryKey(domainId), (oldData: typeof API.domain.GET.dns) => {
                const newData = { ...oldData, data: { status: 200, attributes: { dns_records: response.data } } };
                return newData;
            });
            NXQuery.domain.domainID.dns.getRecord.invalidateQueries(domainId);
            pushNotification({ status: response.status, message: 'Successfully edited record' });
        },
    });
}
/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/

export const updateRecord = Object.freeze({
    useMutation: _useMutation,
});
